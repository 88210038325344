import React from 'react';

const ConfirmUpairModal = ({ isOpen, onConfirm, onCancel, pairedWith }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal">
        <div className="modal-content">
            <h2>Confirm Unpair</h2>
            <p>Are you sure you want to unpair with {pairedWith}?</p>
            <button style={{ marginRight: '100px' }} onClick={onConfirm}>Yes</button>
            <button onClick={onCancel}>No</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmUpairModal;
