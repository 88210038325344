import React from 'react';
import PasswordChallengeModal from './PasswordChallengeModal';

class GameLobby extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPlayer: '',
      selectedChallenge: '',
      password: '',
      isPasswordModalOpen: false,
    };
  }

  openPasswordModal = () => {
    this.setState({ isPasswordModalOpen: true });
  }

    // Adjusted submitPassword method
  submitPassword = (password) => {
    this.setState({ password }, () => {
      this.sendChallengeMessage(this.state.selectedPlayer); 
      this.closePasswordModal();
    });
  };

  // Adjusted closePasswordModal method
  closePasswordModal = () => {
    this.setState({ isPasswordModalOpen: false });
  };

  selectPlayer = (player) => {
    this.setState({ selectedPlayer: player });
  }

  selectChallenge = (challenge) => {
    this.setState({ selectedChallenge: challenge });
  }

  sendChallenge = () => {
    const { websocket, challengedPlayers } = this.props;
    const { selectedPlayer} = this.state;
  
    if (challengedPlayers.includes(selectedPlayer)) {
      const { setUnpairModal, setUnpairMessage } = this.props;
      setUnpairMessage('This player has already been challenged.')
      setUnpairModal(true)
      return;
    }
  
    if (selectedPlayer === 'ChatGPT'){
      // Open the modal and wait for it to close
      this.openPasswordModal();
    } else if (selectedPlayer && websocket) {
      // If the selected player is not 'ChatGPT', send the challenge immediately
      this.sendChallengeMessage(selectedPlayer);
    }
  };
  
  sendChallengeMessage = (selectedPlayer) => {
    const { websocket, onChallenge } = this.props;
    const { password } = this.state;
  
    const challengeMessage = {
      type: 'challenge',
      target: selectedPlayer,
      password: password, 
    };
    websocket.send(JSON.stringify(challengeMessage));
    onChallenge(selectedPlayer);
    this.setState({ selectedPlayer: '', password: '' }); 
  }

  isChallengerBusy = (challengerUsername) => {
    const { players } = this.props;
    const challenger = players.find(player => player.username === challengerUsername);
    return challenger && challenger.status === 'busy';
  }

  handleAcceptChallenge = () => {
    const { websocket, onRespondChallenge } = this.props;
    const { selectedChallenge} = this.state;

    if (selectedChallenge && websocket) {
      if (this.isChallengerBusy(selectedChallenge)) {
        const { setUnpairModal, setUnpairMessage } = this.props;
        setUnpairMessage('The challenger is now busy. Try again later.')  
        setUnpairModal(true)
        return;
      }

      const acceptMessage = {
        type: 'accept',
        challenger: selectedChallenge,
      };
      websocket.send(JSON.stringify(acceptMessage));

      // Remove the accepted challenge from the incomingChallenges list
      onRespondChallenge(selectedChallenge);
      this.setState({
        selectedChallenge: '', // Reset selected challenge
      });
    }
  }
 
  handleDeclineChallenge = () => {
    const { websocket, onRespondChallenge } = this.props;
    const { selectedChallenge} = this.state;
  
    if (selectedChallenge && websocket) {
      const declineMessage = {
        type: 'decline',
        challenger: selectedChallenge,
      };
      websocket.send(JSON.stringify(declineMessage));
  
      // Remove the declined challenge from the incomingChallenges list
      onRespondChallenge(selectedChallenge);
      this.setState({
        selectedChallenge: '', // Reset selected challenge
      });
    }
  }

  // merge styles based on player status
  getStyleForPlayer = (player) => {
    let baseStyle = styles.player;
    if (player.status === 'busy') {
      baseStyle = {...baseStyle, ...styles.busyPlayer}; // merge busyPlayer styles
    } else if (this.props.challengedPlayers.includes(player.username)) {
      baseStyle = {...baseStyle, ...styles.challengedPlayer}; // merge challengedPlayer styles
    }
  
    if (this.state.selectedPlayer === player.username) {
      baseStyle = {...baseStyle, ...styles.selectedPlayer}; // merge selectedPlayer styles on top
    }
    
    return baseStyle;
  }
  
  render() {
    const { players, incomingChallenges, challengedPlayers, username } = this.props;
    const { selectedPlayer, selectedChallenge } = this.state;
    return (
      <div style={styles.lobbyContainer}>
        <div style={styles.section}>
          <div style={styles.title}>Players in Lobby:</div>
          <div style={styles.playerList}>
            {players.filter((player) => player.username !== username).map((player, index) => (
              <div
                key={index}
                style={this.getStyleForPlayer(player)}
                onClick={() => this.selectPlayer(player.username)}
              >
              <div>
                {(player.username === 'ChatGPT' || player.username === 'StockFish') && (
                    <img
                      src={`${process.env.PUBLIC_URL}/${player.username === 'ChatGPT' ? 'openai-logo.png' : 'stockfish-logo.jpg'}`}
                      alt={`${player.username} icon`}
                      style={{ width: '16px', marginLeft: '0px', marginBottom: '-2px', marginRight: '5px'}} 
                    />
                )}
                {player.username}
                </div>
                {challengedPlayers.includes(player.username) && <span style={styles.swordIcon}>🗡️</span>}
              </div>
            ))}
          </div>
          <button style={styles.fullWidthButton} onClick={this.sendChallenge}>Challenge</button>
        </div>
        <div style={styles.section}>
          <div style={styles.title}>Game Requests:</div>
          <div style={styles.requestList}>
            {incomingChallenges.map((challenge, index) => (
              <div
                key={index}
                style={selectedChallenge === challenge ? styles.selectedChallenge : styles.challenge}
                onClick={() => this.selectChallenge(challenge)}
              >
                {challenge}
              </div>
            ))}
          </div>
          <button style={styles.halfWidthButton} onClick={this.handleAcceptChallenge}>Accept</button>
          <button style={{...styles.halfWidthButton, marginRight: 0}} onClick={this.handleDeclineChallenge}>Decline</button>
        </div>
        <PasswordChallengeModal
        isOpen={this.state.isPasswordModalOpen}
        onSubmit={this.submitPassword}
        onCancel={this.closePasswordModal}
        />
      </div>
    );
  }
}

const styles = {
  selectedPlayer: {
    padding: '2px 5px',
    cursor: 'pointer',
    border: '2px solid green', // Adding a yellow border for additional visibility
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  lobbyContainer: {
    width: '200px',
    fontFamily: 'Arial, sans-serif',
    fontSize: '14px',
  },
  section: {
    marginBottom: '20px',
  },
  title: {
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  playerList: {
    border: '1px solid black',
    height: '100px',
    marginBottom: '10px',
    borderRadius: '5px',
    overflowY: 'auto',
  },
  player: {
    padding: '2px 5px',
  },
  fullWidthButton: {
    width: '100%',
    padding: '10px',
  },
  halfWidthButton: {
    width: '49%',
    padding: '10px',
    marginRight: '2%',
  },
  requestList: {
    border: '1px solid black',
    height: '100px',
    marginBottom: '10px',
    borderRadius: '5px',
    overflowY: 'auto',
  },
  challengedPlayer: {
    padding: '2px 5px',
    backgroundColor: '#F0F0F0', // Subtle background color
    color: '#333', // Text color for challenged players
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  swordIcon: {
    marginLeft: '10px',
    fontSize: '16px',
  },
  selectedChallenge: {
    padding: '2px 5px',
    backgroundColor: '#DDEEFF', // Highlight color for selected challenge
    cursor: 'pointer',
  },
  challenge: {
    padding: '2px 5px',
    cursor: 'pointer',
  },
  busyPlayer: {
    backgroundColor: 'red', // Red background for busy players
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};

export default GameLobby;
