import React, { useState } from 'react';

const PasswordChallengeModal = ({ isOpen, onSubmit, onCancel }) => {
  const [password, setPassword] = useState('');

  if (!isOpen) return null;

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  }

  const handleSubmit = () => {
    onSubmit(password);
    setPassword(''); // Reset password after submission
  }

  const bulletPointStyle = {
    listStyleType: 'disc', // Use 'disc' for bullet points
    listStylePosition: 'inside', // Position bullets inside the content flow
    marginLeft: '0', // Align bullets with the modal content
    paddingLeft: '0', // No padding on the left
    textAlign: 'left', // Align text to the left
  };

  const listItemStyle = {
    textAlign: 'left', // Align text to the left within the list item
    display: 'list-item', // Maintain list item display type
  };

  return (
    <div className="modal-backdrop">
      <div className="modal">
        <div className="modal-content">
          <h2>Password Required</h2>
          <p>Kindly, please keep these in mind.</p>
          <ul style={bulletPointStyle}>
            <li style={listItemStyle}>ChatGPT is not a great chess player.</li>
            <li style={listItemStyle}>It may take some time to generate moves.</li>
            <li style={listItemStyle}>The chat sessions with chatGPT is different from the chess session.</li>
          </ul>
          <input 
            type="text" 
            value={password}
            onChange={handlePasswordChange}
            style={{ marginBottom: '10px' }}
          />
          <button style={{ marginRight: '10px' }} onClick={handleSubmit}>Submit</button>
          <button onClick={onCancel}>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default PasswordChallengeModal;
