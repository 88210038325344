import React, {useRef, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';

const Square = ({ piece, x, y, onDrop, setHoveredSquare, hoveredSquare, whiteKingInCheck, blackKingInCheck, isHighlighted, isFlipped }) => {
  const [, drop] = useDrop({
    accept: 'piece',
    drop: (item) => {
      onDrop(item.x, item.y, x, y);
      setHoveredSquare({ x: null, y: null }); // Reset on drop
    },
    hover: () => {
      setHoveredSquare({ x, y }); // Update hovered square
    },
  });

  const isOver = hoveredSquare.x === x && hoveredSquare.y === y;

  const pieceImageRef = useRef(null);

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: 'piece',
    item: { x, y, imgSrc: piece ? `${process.env.PUBLIC_URL}/chess_pieces/${piece.color}${piece.type}.svg` : null },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        // Handle the drop logic here
      }
    },
  }));

  useEffect(() => {
    if (pieceImageRef.current && piece) {
      const img = new Image();
      img.src = `${process.env.PUBLIC_URL}/chess_pieces/${piece.color}${piece.type}.svg`; // Directly use the image source
      img.onload = () => {
        // Set custom styles for the drag preview
        const previewImg = document.createElement('div');
        previewImg.style.transform = 'scale(0)'; 
        previewImg.appendChild(img);
  
        preview(previewImg);
      };
    }
    // Adding 'piece' as a dependency, so the effect runs when the piece changes
  }, [piece, preview]);
  

  let pieceImage = null;
  if (piece) {
    
    const pieceFileName = `${process.env.PUBLIC_URL}/chess_pieces/${piece.color}${piece.type}.svg`;
    pieceImage = (
      <img 
        ref={pieceImageRef} 
        src={pieceFileName} 
        alt={`${piece.color}${piece.type}`} 
        style={{ 
          width: '100%', 
          height: '100%', 
          background: 'transparent', 
          border: 'none', 
          transform: isFlipped ? 'rotate(180deg)' : 'none' 
        }} 
      />
    );
  }
  

  // Connect the drag source and ref
  drag(pieceImageRef);

  let checkIndicator = null;
  if (piece && piece.type === 'k') {
    if ((piece.color === 'w' && whiteKingInCheck) || (piece.color === 'b' && blackKingInCheck)) {
      checkIndicator = (
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          pointerEvents: 'none',
          zIndex: 999,
        }}>
          <div style={{
            width: '40px',
            height: '40px',
            border: '2px solid red',
            borderRadius: '50%',
            position: 'relative'
          }}>
            {/* Cross bars */}
            <div style={{
              position: 'absolute',
              width: '100%',
              height: '6px',
              backgroundColor: 'red',
              top: '50%',
              left: 0,
              transform: 'translateY(-50%) rotate(45deg)'
            }}></div>
            <div style={{
              position: 'absolute',
              width: '100%',
              height: '6px',
              backgroundColor: 'red',
              top: '50%',
              left: 0,
              transform: 'translateY(-50%) rotate(-45deg)'
            }}></div>
          </div>
        </div> 
      );
    }
  }

  return (
    <div ref={drop} style={{ 
      width: '50px', 
      height: '50px', 
      backgroundColor: isOver ? 'lightblue' : isHighlighted ? '#d9f7be' : (x + y) % 2 === 1 ? 'gray' : 'white',
      position: 'relative', 
    }}>
      {checkIndicator}
      <div ref={piece ? drag : preview} style={{ opacity: isDragging ? 0 : 1 }}>
        {pieceImage}
      </div>
    </div>
  );
};

export default Square