import React from 'react';
import './CallingModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

const CallingModal = ({ isOpen, player, onCancel }) => {
  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <p>Calling {player}...</p>
        <button className="cancel-call-button" onClick={onCancel}>
          <FontAwesomeIcon icon={faPhone} className="cancel-icon" />
        </button>
      </div>
    </div>
  );
};

export default CallingModal;
