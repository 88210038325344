import React, { useState, useRef, useEffect } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPhone, faPaperclip} from '@fortawesome/free-solid-svg-icons';
import './ChatWindow.css';
import CallingModal from './CallingModal';
import AnswerModal from './AnswerModal';
import UnpairConfirmationModal from './ConfirmUpairModal'

const ChatWindow = ({ websocket, pairedWith, dataChannel, dataChannelOpen, activateAudio, deactivateAudio }) => {
  const [isUnpairModalOpen, setIsUnpairModalOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState('');
  const [iconClicked, setIconClicked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAnswerModalOpen, setIsAnswerModalOpen] = useState(false);
  const [callingPlayer, setCallingPlayer] = useState('');
  const [inCall, setInCall] = useState(false);
  const [callTime, setCallTime] = useState(0);
  const timerRef = useRef(null);
  const callingSoundRef = useRef(new Audio(process.env.PUBLIC_URL + '/sound.mp3'));
  const timeoutIdRef = useRef(null);
  const receivedFileMetadata = useRef(null);
  let receivedBuffers = [];
  const [ws, setWs] = useState(null);

  useEffect(() => {
    // When the modal is opened, play the sound
    if (isModalOpen) {
      callingSoundRef.current.play();
    }
  
    // When the component unmounts, stop the sound
    return () => {
      callingSoundRef.current.pause();
      callingSoundRef.current.currentTime = 0;
    };
  }, [isModalOpen]); // Only re-run the effect if isModalOpen changes

  useEffect(() => {
    if (pairedWith === 'ChatGPT') {
      console.log('Connecting to the chat part of the chatGPT server...');
      const ws = new WebSocket('wss://chesschat.club:65431/ws');
      setWs(ws);

      ws.onopen = () => {
          console.log('ready to chat with ChatGPT');
      };
  
      ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.type === 'chat-message') {
            setMessages((prevMessages) => [...prevMessages, { sender: 'ChatGPT', text: message.text }]);
        }
      };
    
      ws.onclose = () => {
          console.log('Disconnected from the chat server');
      }

      return () => {
        ws.close();
      }
    }

  }, [pairedWith]);

  useEffect(() => {
    if (isAnswerModalOpen) {
      callingSoundRef.current.play();
    } else {
      callingSoundRef.current.pause();
      callingSoundRef.current.currentTime = 0;
    }
  
    // Cleanup function to stop the ringtone if the component unmounts
    return () => {
      callingSoundRef.current.pause();
      callingSoundRef.current.currentTime = 0;
    };
  }, [isAnswerModalOpen]);
  
  useEffect(() => {
    return () => {
      clearTimeout(timeoutIdRef.current);
    };
  }, []);

  // Effect for handling incoming data channel messages
  useEffect(() => {
    
    const handleMessage = (event) => {
      if (typeof event.data === 'string') {
        const data = JSON.parse(event.data);
        switch (data.type) {
          case 'chat-message':
            setMessages((prevMessages) => [...prevMessages, { sender: pairedWith, text: data.text }]);
            break;
          case 'call-initiated':
            // Logic to handle incoming call notification
            setIsAnswerModalOpen(true);
            setCallingPlayer(data.from); // Set the caller's name
            break;
          case 'call-ended':
            // Logic to handle call termination
            setIsAnswerModalOpen(false);
            setIsModalOpen(false); // If this end was calling, close the CallingModal
            break;
          case 'call-declined': 
            handleCancelCall(); 
            break;
          case 'call-answered':
            handleCancelCall(); 
            handleConversation();
            break;
          case 'end-call':
            endCall();
            break;
          case 'file-metadata':
            receivedFileMetadata.current = data;
            // Add a new message for the incoming file
            setMessages(prevMessages => [...prevMessages, { 
              sender: pairedWith, 
              text: data.name, 
              isFile: true, 
              url: '', 
              progress: 0 // Initial progress
            }]);
            break;
        }
      } else {
        // Handling file data (chunks)
        console.log("receivedFileMetadata:", receivedFileMetadata)
        handleFileData(event.data);
      }
    }
  
    if (dataChannel.current && dataChannelOpen) {
      console.log("Setting up data channel event handlers");
      dataChannel.current.onmessage = (event) => {
        handleMessage(event);
      };
    }
  
    // // Clean up the event listener when the component unmounts
    // return () => {
    //   if (dataChannel.current) {
    //     dataChannel.current.onmessage = null;
    //   }
    // };

  }, [dataChannel, dataChannelOpen, pairedWith, setIsAnswerModalOpen, setIsModalOpen, setCallingPlayer]); // Add dependencies as necessary
  
  // const handleSendClick = () => {
  //   if (inputText.trim() && dataChannel.current && dataChannel.current.readyState === 'open') {
  //     console.log("Sending message:", inputText);
  //     const textMessage = JSON.stringify({ type: 'chat-message', text: inputText });
  //     dataChannel.current.send(textMessage);
  //     setMessages((prevMessages) => [...prevMessages, { sender: 'You', text: inputText }]);
  //     setInputText('');
  //   }
  // }; old code before chatGPT chat

  const handleSendClick = () => {
    if (inputText.trim()) {
      // Check if paired with ChatGPT and WebSocket connection is open
      if (pairedWith === 'ChatGPT' && ws?.readyState === WebSocket.OPEN) {
        console.log("Sending message to ChatGPT server:", inputText);
        // Directly use the messages array as is for the conversation history
        const messageWithHistory = {
          type: 'chat-message',
          text: inputText,
          conversationHistory: messages // Use existing messages array
        };
        // Send the message including the conversation history to the server
        ws.send(JSON.stringify(messageWithHistory));
      } else if (dataChannel.current && dataChannel.current.readyState === 'open') {
        console.log("Sending message through data channel:", inputText);
        const textMessage = JSON.stringify({ type: 'chat-message', text: inputText });
        dataChannel.current.send(textMessage);
      }
      setMessages((prevMessages) => [...prevMessages, { sender: 'You', text: inputText }]);
      setInputText('');
    }
  };
  
  const handleInputChange = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) { // Checks if Enter was pressed without the Shift key
      e.preventDefault(); // Prevents the default action (new line)
      handleSendClick();
    } else {
      setInputText(e.target.value);
    }
  };

  const handlePhoneIconClick = () => {
    if (!iconClicked) {
      setCallingPlayer(pairedWith);
      setIsModalOpen(true);
      setIconClicked(true);
      callingSoundRef.current.loop = true;
      callingSoundRef.current.play();
  
      timeoutIdRef.current = setTimeout(() => {
        callingSoundRef.current.pause();
        callingSoundRef.current.currentTime = 0;
        setIsModalOpen(false);
        setIconClicked(false);

        if(dataChannel.current) {
          dataChannel.current.send(JSON.stringify({ type: 'call-ended' }));
        }

      }, 30000);
      
      if(dataChannel.current) {
        dataChannel.current.send(JSON.stringify({ type: 'call-initiated', from: pairedWith }));
      }
    } else {
      endCall();
      dataChannel.current.send(JSON.stringify({ type: 'end-call' }));
    }
  };
  
  const handleCancelCall = () => {
    // Clear the timeout using the ref
    clearTimeout(timeoutIdRef.current);
    // Use the ref to the audio object to pause and reset it
    callingSoundRef.current.pause();
    callingSoundRef.current.currentTime = 0;
    setIsModalOpen(false);
    setIconClicked(false);

    if(dataChannel.current) {
      dataChannel.current.send(JSON.stringify({ type: 'call-ended' }));
    }

  };

  const handleCallAnswered = () => {
    setIsAnswerModalOpen(false); // Close the AnswerModal
  
    if (dataChannel.current && dataChannel.current.readyState === 'open') {
      dataChannel.current.send(JSON.stringify({ type: 'call-answered', from: pairedWith }));
    }
  
    handleConversation();
  };
  
  const handleCallDeclined = () => {
    setIsAnswerModalOpen(false); // Close the AnswerModal
  
    if (dataChannel.current && dataChannel.current.readyState === 'open') {
      dataChannel.current.send(JSON.stringify({ type: 'call-declined' }));
      console.log("Sent call-declined message to the caller.");
    }
  };

  const handleConversation = () => {
    setInCall(true); 
    setIconClicked(true); 
    setCallTime(0); // Reset timer

    // Start a timer
    timerRef.current = setInterval(() => {
      setCallTime((prevTime) => prevTime + 1); // Increment timer every second
    }, 1000);
    activateAudio();
  };
  
  const endCall = () => {
    setInCall(false);
    setIconClicked(false); 
    clearInterval(timerRef.current); // Stop the timer
    deactivateAudio();
  };

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const confirmUnpair = () => {
    setIsUnpairModalOpen(false);
    websocket.send(JSON.stringify({ 
      type: 'unpair',
      target: pairedWith
    }));
  };
  
  const cancelUnpair = () => {
    setIsUnpairModalOpen(false);
  };
  
  const handleUnpair = () => {
    setIsUnpairModalOpen(true);
  }

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleFileUpload(file);
    }
  };
  
  const handleFileUpload = (file) => {
    if (dataChannel.current && dataChannel.current.readyState === 'open') {
      // Send file metadata first
      const metadata = JSON.stringify({
        type: 'file-metadata',
        name: file.name,
        size: file.size,
        fileType: file.type
      });
      dataChannel.current.send(metadata);
  
      // Add a message for the file being sent
      setMessages(prevMessages => [...prevMessages, { 
        sender: 'You', 
        text: file.name, 
        isFile: true, 
        url: '', 
        progress: 0 // Progress as a percentage (0 to 100)
      }]);

      // Then, read and send the file in chunks
      sendFileInChunks(file);
    }
  };
  
  const CHUNK_SIZE = 16384; // 16KB, adjust as needed
  
  const sendFileInChunks = (file) => {
    const fileReader = new FileReader();
    let offset = 0;
  
    fileReader.onload = (e) => {
      dataChannel.current.send(e.target.result);
      offset += e.target.result.byteLength;
    
      // Update progress
      const progress = Math.min((offset / file.size) * 100, 100);
      updateFileMessageProgress(file.name, progress);
    
      if (offset < file.size) {
        readSlice(offset);
      }
    };
    
    let accumulatedProgressSender = 0;
    let lastUpdateSenderTime = Date.now();
    const updateIntervalSender = 1000; // 1 second
    
    const updateFileMessageProgress = (fileName, newProgress) => {
      const currentTime = Date.now();
    
      // Directly set progress to 100% if file transfer is complete
      if (newProgress >= 100) {
        setMessages(prevMessages => prevMessages.map(msg =>
          msg.isFile && msg.text === fileName ? { ...msg, progress: 100 } : msg
        ));
        accumulatedProgressSender = 0; // Reset accumulated progress
        lastUpdateSenderTime = currentTime; // Reset last update time
      } else if (currentTime - lastUpdateSenderTime > updateIntervalSender) {
        // Throttle updates: only update if the interval has passed
        setMessages(prevMessages => prevMessages.map(msg =>
          msg.isFile && msg.text === fileName ? { ...msg, progress: accumulatedProgressSender } : msg
        ));
        lastUpdateSenderTime = currentTime; // Update the last update time
        accumulatedProgressSender = newProgress; // Update the accumulated progress
      } else {
        accumulatedProgressSender = newProgress; // Keep accumulating progress
      }
    };
    
    const readSlice = (o) => {
      const slice = file.slice(o, o + CHUNK_SIZE);
      fileReader.readAsArrayBuffer(slice);
    };
  
    readSlice(0);
  };

  const handleFileData = (data) => {
    receivedBuffers.push(data);
  
    // Calculate the current progress
    const totalReceived = receivedBuffers.reduce((acc, buffer) => acc + buffer.byteLength, 0);
    const progress = (totalReceived / receivedFileMetadata.current.size) * 100;
  
    // Update the message with the new progress
    updateReceivedFileProgress(receivedFileMetadata.current.name, progress);
  
    // Check if all data has been received
    if (receivedFileMetadata.current && totalReceived >= receivedFileMetadata.current.size) {
      const blob = new Blob(receivedBuffers, { type: receivedFileMetadata.current.fileType });
      const url = URL.createObjectURL(blob);
      const fileName = receivedFileMetadata.current.name;
  
      // Finalize the progress to 100% and update URL
      setMessages(prevMessages => prevMessages.map(msg => {
        if (msg.isFile && msg.text === fileName) {
          return { ...msg, url, progress: 100 };
        }
        return msg;
      }));
  
      // Reset for the next file transfer
      receivedFileMetadata.current = null;
      receivedBuffers = [];
    } else {
      // Throttled progress update
      updateReceivedFileProgress(receivedFileMetadata.current.name, progress);
    }
  };
  
  let accumulatedProgressReceiver = 0;
  let lastUpdateReceiverTime = Date.now();
  const updateIntervalReceiver = 1000; // Update every 1 second
  
  const updateReceivedFileProgress = (fileName, newProgress) => {
    const currentTime = Date.now();
  
    // Directly set progress to 100% if file transfer is complete
    if (newProgress >= 100) {
      setMessages(prevMessages => prevMessages.map(msg =>
        msg.isFile && msg.text === fileName ? { ...msg, progress: 100 } : msg
      ));
      accumulatedProgressReceiver = 0; // Reset accumulated progress
      lastUpdateReceiverTime = currentTime; // Reset last update time
    } else if (currentTime - lastUpdateReceiverTime > updateIntervalReceiver) {
      // Throttle updates: only update if the interval has passed
      setMessages(prevMessages => prevMessages.map(msg =>
        msg.isFile && msg.text === fileName ? { ...msg, progress: accumulatedProgressReceiver } : msg
      ));
      lastUpdateReceiverTime = currentTime; // Update the last update time
      accumulatedProgressReceiver = newProgress; // Update the accumulated progress
    } else {
      accumulatedProgressReceiver = newProgress; // Keep accumulating progress
    }
  };

  return (
    <div className="chat-window">
      <div className="chat-header">
        {inCall ? (
          <span>Call with {pairedWith}: <span className="call-timer">{formatTime(callTime)}</span></span>
        ) : (
          <span>Chat with {pairedWith}</span>
        )}
        {/* <FontAwesomeIcon
          icon={faPhone}
          className={`phone-icon ${iconClicked ? 'icon-clicked' : ''}`}
          onClick={handlePhoneIconClick}
        /> */}
      </div>
      <div className="messages-area">
      {messages.map((message, index) => (
        <div key={index} className={`message ${message.sender === 'You' ? 'user-message' : 'other-message'}`}>
          {message.isFile ? (
            <>
              <div className="message-text">
                <a href={message.url} download={message.text}>{message.text}</a>
              </div>
              <div className="file-progress" style={{ width: `${message.progress}%` }}></div>
            </>
          ) : (
            message.text
          )}
        </div>
      ))}
      </div>
      <div className="input-area">
        <textarea
          value={inputText}
          onChange={handleInputChange}
          onKeyDown={handleInputChange}
          placeholder='Type a message...'
        />
        <button
          className="send-button"
          // onClick={() => document.getElementById('fileInput').click()}
          onClick={handleSendClick}
        >
        {/* <FontAwesomeIcon icon={faPaperclip}/> */}
        Send
        </button>
        <input
        type="file"
        id="fileInput"
        style={{ display: 'none' }}
        onChange={handleFileSelect}
        />
      </div>
      <button className="unpair-button" onClick={handleUnpair}>Unpair</button>
      <CallingModal isOpen={isModalOpen} player={pairedWith} onCancel={handleCancelCall} />
      <AnswerModal isOpen={isAnswerModalOpen} caller={pairedWith} onAnswer={handleCallAnswered} onDecline={handleCallDeclined} />
      <UnpairConfirmationModal 
        isOpen={isUnpairModalOpen} 
        onConfirm={confirmUnpair} 
        onCancel={cancelUnpair} 
        pairedWith={pairedWith}
      />
    </div>
  );
};

export default ChatWindow


