import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import LoginScreen from './LoginScreen';

const root = ReactDOM.createRoot(document.getElementById('root'));

const MainComponent = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [websocket, setWebsocket] = useState(null);

  const handleLoginSuccess = (username, ws) => {
    setUsername(username);
    setWebsocket(ws);
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername('');
    setWebsocket(null);
  };

  console.log('Version: 1.0.5 - 2024-02-03,09:49 - GPT enabled');

  return (
    <React.StrictMode>
      {isLoggedIn ? (
        <App 
          username={username} 
          websocket={websocket} 
          onLogout={handleLogout} 
        />
      ) : (
        <LoginScreen onLoginSuccess={handleLoginSuccess} />
      )}
    </React.StrictMode>
  );

};

root.render(<MainComponent />);
