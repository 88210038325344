const DisconnectionModal = ({ isOpen, onClose, onLogout }) => {
    if (!isOpen) return null;

    const handleClose = () => {
        onClose();
        onLogout(); // This will reset the isLoggedIn state in MainComponent
      };
  
    return (
      <div className="modal-backdrop">
        <div className="modal">
        <div className="modal-content">
            <h2>Disconnected</h2>
            <p>You have been disconnected.</p>
            <button onClick={handleClose}>Reconnect</button>
        </div>
        </div>
      </div>
    );
  };

export default DisconnectionModal;