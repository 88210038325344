import React from 'react';

const ConfirmModal = ({ isOpen, onConfirm, onCancel, message, setIsOpen}) => {
  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
        <div className="modal">
        <div className="modal-content">
            <p>{message}</p>
            <button style={{ marginRight: '100px' }} onClick={onConfirm}>Yes</button>
            <button onClick={onCancel}>No</button>
        </div>
        </div>
    </div>
  );
};

export default ConfirmModal;