import React from 'react';

const UnpairModal = ({ isOpen, message, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-backdrop">
        <div className="modal">
        <div className="modal-content">
            <p>{message}</p>
            <button onClick={onClose}>Okay</button>
        </div>
        </div>
    </div>
  );
};

export default UnpairModal;
