import React, { useState, useEffect, useRef } from 'react';
import GameLobby from './components/GameLobby';
import ChatWindow from './components/ChatWindow';
import ChessGame from './components/ChessGame';
import DisconnectionModal from './components/DisconnectionModal';
import UnpairModal from './components/UnpairModal';
import './App.css'; 

function App({ username, websocket, onLogout}) {
  
  const defaultPlayers = [
    { username: 'ChatGPT', status: 'available' },
    // { username: 'StockFish', status: 'available' },
  ];
  
  const [turn, setTurn] = useState('');
  const [players, setPlayers] = useState(defaultPlayers);
  const [incomingChallenges, setIncomingChallenges] = useState([]);
  const [challengedPlayers, setChallengedPlayers] = useState([]);
  const [dataChannelOpen, setDataChannelOpen] = useState(false);
  const [gameStateChannelOpen, setGameStateChannelOpen] = useState(false); 
  const [isPaired, setIsPaired] = useState(false);
  const [pairedWith, setPairedWith] = useState('opponent');
  const [ischallenger, setIsChallenger] = useState(false);  
  const [isDisconnected, setIsDisconnected] = useState(false);
  const [unpairMessage, setUnpairMessage] = useState('');
  const [showUnpairModal, setShowUnpairModal] = useState(false);
  const peerConnection = useRef(null);
  const dataChannel = useRef(null);
  const localStream = useRef(null);
  const gameStateChannel = useRef(null);

  useEffect(() => {
      console.log('sending let_em_know to server...');
      websocket.send(JSON.stringify({ type: 'let_em_know' }));
  },[]); 

  useEffect(() => {
    websocket.onmessage = (event) => {
      const message = JSON.parse(event.data);
  
      switch (message.type) {
        case 'user_list':
          const updatedIncomingChallenges = incomingChallenges.filter(challenger => 
            message.users.some(user => user.username === challenger));
            setPlayers([...defaultPlayers, ...message.users]);
          
          setIncomingChallenges(updatedIncomingChallenges);
          break;
  
        case 'challenge':
          setIncomingChallenges(prev => [...prev, message.username]);
          break;
  
        case 'decline':
        case 'accept':
          setChallengedPlayers(prev => prev.filter(player => player !== message.username)); // not DRY
          if (message.type === 'accept') {
            pair(message.username)
            setIsChallenger(true);
          }
          break;
  
        case 'unpair':
          unpair(message.message);
          break;
  
        case 'pair':
          pair(message.target);
          break;

        case 'wrong_password':
          setChallengedPlayers(prev => prev.filter(player => player !== message.username)); // not DRY
          unpair(message.message);
          break;
  
        case 'offer':
          handleOffer(message.offer, message.target);
          break;
  
        case 'answer':
          handleAnswer(message.answer);
          break;
  
        case 'candidate':
          handleIceCandidate(message.candidate);
          break;
      }
    };

    websocket.onclose = () => {
      console.log('WebSocket Disconnected');
      setIsDisconnected(true);
    };
  
    websocket.onerror = (error) => {
      console.error('WebSocket Error', error);
      setIsDisconnected(true);
    };

  }, [websocket, incomingChallenges]);

  
  useEffect(() => {
    if (isPaired) {
      initializePeerConnection();
    }
  }, [isPaired]);
  
  const initializePeerConnection = async () => {
    if (peerConnection.current) return;
    
    // peerConnection.current = new RTCPeerConnection({
    //   iceServers: [
    //     { urls: 'stun:stun.l.google.com:19302' },
    //     { urls: 'turn:chesschat.club:5349', username: 'chesschat', credential: 'voice' },
    // ]
    // });

    const configuration = {
      iceServers:[ 
        { urls: 'stun:stun.l.google.com:19302' },
        {
          urls: "stun:stun.relay.metered.ca:80",
        },
        {
          urls: "turn:standard.relay.metered.ca:80",
          username: "56498fa120332c48423d70ee",
          credential: "ErS+kEcyyAaIAI5W",
        },
        {
          urls: "turn:standard.relay.metered.ca:80?transport=tcp",
          username: "56498fa120332c48423d70ee",
          credential: "ErS+kEcyyAaIAI5W",
        },
        {
          urls: "turn:standard.relay.metered.ca:443",
          username: "56498fa120332c48423d70ee",
          credential: "ErS+kEcyyAaIAI5W",
        },
        {
          urls: "turns:standard.relay.metered.ca:443?transport=tcp",
          username: "56498fa120332c48423d70ee",
          credential: "ErS+kEcyyAaIAI5W",
        },
      ],
    };
    
    peerConnection.current = new RTCPeerConnection(configuration);
    
    // Request the user's microphone
      // try {
      //   console.log('peerConnection.current: ', peerConnection.current);
      //   const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      //   localStream.current = stream;
      //   console.log('peerConnection.current: ', peerConnection.current);
      //   // Add each track from the stream to the peer connection
      //   stream.getTracks().forEach((track) => {
      //     peerConnection.current.addTrack(track, stream);
      //   });
      // } catch (error) {
      //   console.error('Error getting microphone', error);
      // }

    peerConnection.current.ontrack = (event) => {
      const [remoteStream] = event.streams;
      const audioElement = document.getElementById('remoteAudio');
      if (audioElement) {
        audioElement.srcObject = remoteStream;
        console.log('Remote stream attached to audio element');
      } else {
        console.error('Audio element not found in the DOM');
      }
    };
    
    // Set up the ondatachannel event handler for both peers
    peerConnection.current.ondatachannel = (event) => {
      if (event.channel.label === 'chat') {
          dataChannel.current = event.channel;
          setupDataChannelEvents();
      } else if (event.channel.label === 'gameState') {
          gameStateChannel.current = event.channel;
          setupGameStateChannelEvents();
      }
    };
  
    console.log('shooting ice candidates at ' + pairedWith + '...');
    peerConnection.current.onicecandidate = (event) => {
      if (event.candidate) {
        websocket.send(JSON.stringify({
          type: 'candidate',
          candidate: JSON.stringify(event.candidate),
          target: pairedWith
        }));
      } 
    };
  
    if (ischallenger) {
      // Challenger creates the data channel
      dataChannel.current = peerConnection.current.createDataChannel('chat');
      setupDataChannelEvents();

      gameStateChannel.current = peerConnection.current.createDataChannel('gameState');
      setupGameStateChannelEvents();

      createOffer();
    }
    // The non-challenger will set up the peer connection and wait for the offer
  };
  
  const createOffer = async () => {
    const offer = await peerConnection.current.createOffer();
    await peerConnection.current.setLocalDescription(offer);
    websocket.send(JSON.stringify({
      type: 'offer',
      offer: JSON.stringify(offer),
      target: pairedWith
    }));
  };

  const handleOffer = async (offerString, from) => {
    const offer = JSON.parse(offerString);
    await peerConnection.current.setRemoteDescription(new RTCSessionDescription(offer));
    const answer = await peerConnection.current.createAnswer();
    await peerConnection.current.setLocalDescription(answer);
    websocket.send(JSON.stringify({
      type: 'answer',
      answer: JSON.stringify(answer),
      target: from
    }));
  };

  const handleAnswer = async (answerString) => {
    const answer = JSON.parse(answerString);
    await peerConnection.current.setRemoteDescription(new RTCSessionDescription(answer));
  };

  const handleIceCandidate = async (candidateString) => {
    try {
        const candidate = JSON.parse(candidateString);
        await peerConnection.current.addIceCandidate(new RTCIceCandidate(candidate));
    } catch (e) {
        console.error('Error adding received ice candidate', e);
    }
  };

  const setupDataChannelEvents = () => {
  
    dataChannel.current.onopen = () => {
      console.log('Data channel opened');
      setDataChannelOpen(true); 
      deactivateAudio();
    };

    dataChannel.current.onclose = () => {
      console.log('Data channel closed');
      setDataChannelOpen(false);
      dataChannel.current = null;
    };

  };

  const setupGameStateChannelEvents = () => {
    
    gameStateChannel.current.onopen = () => {
        console.log('Game state data channel opened');
        setGameStateChannelOpen(true); 
    };

    gameStateChannel.current.onclose = () => {
        console.log('Game state data channel closed');
        setGameStateChannelOpen(false);
        gameStateChannel.current = null;
    };
  };

  const activateAudio = async () => {
    // if (!localStream.current) {
    //   // Only get the user media if we don't already have a stream
    //   try {
    //     const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    //     localStream.current = stream;
    //   } catch (error) {
    //     console.error('Error getting microphone', error);
    //     return;
    //   }
    // }
  
    // localStream.current.getTracks().forEach(track => {
    //   if (track.kind === 'audio') {
    //     track.enabled = true; // Re-enable the audio track
    //   }
    // });
  
    // if (peerConnection.current && localStream.current) {
    //   // Add each track from the stream to the peer connection
    //   localStream.current.getTracks().forEach(track => {
    //     const sender = peerConnection.current.getSenders().find(s => s.track && s.track.kind === track.kind);
    //     if (sender) {
    //       sender.replaceTrack(track);
    //     } else {
    //       peerConnection.current.addTrack(track, localStream.current);
    //     }
    //   });
    // }

  };
  
  const deactivateAudio = () => {
    // if (localStream.current) {
    //   localStream.current.getTracks().forEach(track => {
    //     if (track.kind === 'audio') {
    //       track.stop(); // Stops the track and releases the microphone
    //     }
    //   });
    //   localStream.current = null; // Clear the stream to signal that it needs to be reacquired
    // }
  };

  const pair = (target) => {
    console.log("paired")
    setIsPaired(true);
    setPairedWith(target);
  }

  const unpair = (message) => {
    // Close peer connection
    if (peerConnection.current) {
      peerConnection.current.close();
      peerConnection.current = null;
    }
  
    // Close data channels
    if (dataChannel.current) {
      dataChannel.current.close();
      dataChannel.current = null;
    }
  
    if (gameStateChannel.current) {
      gameStateChannel.current.close();
      gameStateChannel.current = null;
    }
  
    // Stop local media tracks
    if (localStream.current) {
      // localStream.current.getTracks().forEach(track => track.stop());
      // localStream.current = null;
    }
  
    // Reset state variables
    setIsPaired(false);
    setPairedWith(null);
    setIsChallenger(false);
    setDataChannelOpen(false);
    setGameStateChannelOpen(false);
    if (message !== "You have unpaired your partner.") {
      setUnpairMessage(message);
      setShowUnpairModal(true);
    }
  };
  
  return (
    <div className="App">
      <div className="topBar"></div>
      <h1 className="heading">{isPaired ? (turn === 'your' ? "Your Move!" : `${pairedWith}'s Move!`) : `Chess Lobby - ${username}`}</h1> 
      {/* <audio id="remoteAudio" autoPlay playsInline></audio> */}
      <div className="mainContent">
        { isPaired ? 
          <ChatWindow 
            websocket={websocket} 
            pairedWith={pairedWith}
            peerConnection={peerConnection}
            dataChannel={dataChannel}
            dataChannelOpen={dataChannelOpen}
            activateAudio={activateAudio}
            deactivateAudio={deactivateAudio}
          /> :
          <GameLobby 
            websocket={websocket} 
            username={username} 
            players={players}
            incomingChallenges={incomingChallenges}
            challengedPlayers={challengedPlayers}
            onChallenge={(player) => setChallengedPlayers(prev => [...prev, player])}
            onRespondChallenge={(challenger) => setIncomingChallenges(prev => prev.filter(challenge => challenge !== challenger))}
            setUnpairMessage={setUnpairMessage}
            setUnpairModal={setShowUnpairModal}
          />
        }
        <ChessGame 
          gameDataChannel={gameStateChannel} 
          gameDataChannelOpen={gameStateChannelOpen}
          isPaired={isPaired}
          ischallenger={ischallenger}
          setTurn={setTurn}
          pairedWith={pairedWith}
          websocket={websocket} 
        /> 
      </div>
      <DisconnectionModal 
      isOpen={isDisconnected} 
      onClose={() => setIsDisconnected(false)}
      onLogout={onLogout} 
      />
      <UnpairModal 
        isOpen={showUnpairModal} 
        message={unpairMessage} 
        onClose={() => setShowUnpairModal(false)}
      />
    </div>
  );
}

export default App;
