import React, { useEffect, useState } from 'react';
import { DndProvider} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Chess } from 'chess.js';
import Square from './Square';
import ConfirmModal from './ConfirmModal';
import UnpairModal from './UnpairModal';

const Board = ({isPaired, ischallenger, gameDataChannel, gameDataChannelOpen, setTurn, pairedWith, websocket}) => {
  const [chess, setChess] = useState(new Chess());
  // const [chess, setChess] = useState(() => new Chess()); 
  const [ws, setWs] = useState(null);
  const [move, setMove] = useState(null); 
  const [board, setBoard] = useState(chess.board());
  const [isGameOver, setIsGameOver] = useState(false);
  const [winner, setWinner] = useState(null);
  const [whiteKingInCheck, setWhiteKingInCheck] = useState(false);
  const [blackKingInCheck, setBlackKingInCheck] = useState(false);
  const [lastMove, setLastMove] = useState({ fromX: null, fromY: null, toX: null, toY: null });
  const [hoveredSquare, setHoveredSquare] = useState({ x: null, y: null });
  const [isFlipped, setIsFlipped] = useState(false);
  const [isYourTurn, setIsYourTurn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [ChatGPTFailMessage, setChatGPTFailMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    console.log('pairedWith:', pairedWith);
    if (pairedWith === 'ChatGPT') {
      console.log('Connecting to the WebSocket server...');
      const ws = new WebSocket('wss://chesschat.club:65430/ws');
      setWs(ws);

      ws.onerror = () => {
        setErrorMessage('Failed to connect with ChatGPT, please try again later.');
        setShowErrorModal(true);
        unpair();
      };

      ws.onopen = () => {
          console.log('Connected to the WebSocket server');
      };
  
      ws.onmessage = (event) => {
          const message = JSON.parse(event.data);
          handleMessage(message);
      };

      ws.onclose = () => {
          console.log('Disconnected from the WebSocket server');
      }

      return () => {
        ws.close();
        resetGame();
      }

    }
  }, [pairedWith]);

  const handleMessage = (message) => {
    switch(message.type) {
        case 'move':
            const moveUCI = message.move;
            const from = moveUCI.substring(0, 2)
            const to = moveUCI.substring(2, 4)
            const move = {
              'from': from,
              'to': to,
            }
            addMoveToChessBoard(message.fen, move);
            break;
        case 'error':
            setChatGPTFailMessage('ChatGPT made an invalid move, do you want it to try again?');
            setShowModal(true);
            break;
        case 'error-fatal':
            setErrorMessage(message.message);
            setShowErrorModal(true);
            unpair();
            break;
        default:
            console.error('Received unknown message type from server:', message.type);
            setErrorMessage(message.message);
            setShowErrorModal(true);
    }
  };

  useEffect(() => {
    if (!gameDataChannelOpen) {
      setIsFlipped(false);
      resetGame();
    }
  }, [gameDataChannelOpen]);

  useEffect(() => {
    if (isPaired) {
      const isblack = ischallenger ? false : true;
      setIsFlipped(isblack);
      resetGame();
      // Set the initial turn when paired
      setTurn(ischallenger ? 'your' : 'opponent');
    } else {
      // Reset turn when not paired
      setTurn('');
    }
  }, [isPaired]);

  // Handle incoming messages from the other playe
  useEffect(() => {
    if (gameDataChannel.current && gameDataChannelOpen) {
      gameDataChannel.current.onmessage = (event) => {
        const data = JSON.parse(event.data); // Assuming the incoming message is JSON
        // Determine the type of the message
        switch (data.type) {
          case 'move':
            const move = data.move
            const {fen} = data;
            if (move) {
              addMoveToChessBoard(fen, move);
            }
            break;
        }
      };
    }
    
  }, [gameDataChannel, gameDataChannelOpen]);

  const resetGame = () => {
    const newChessInstance = new Chess();
    setChess(newChessInstance);
    setBoard(newChessInstance.board());
    setIsGameOver(false);
    setWinner(null);
    setWhiteKingInCheck(false);
    setBlackKingInCheck(false);
    setLastMove({ fromX: null, fromY: null, toX: null, toY: null });
    setHoveredSquare({ x: null, y: null });
    setIsYourTurn(ischallenger);
  }

  const handleMove = (fromX, fromY, toX, toY) => {
    const moves = chess.moves({ verbose: true });
    const move = moves.find(
      (m) =>
        m.from === String.fromCharCode(97 + fromY) + (8 - fromX) &&
        m.to === String.fromCharCode(97 + toY) + (8 - toX)
    );
    
    if (isYourTurn && move && ((gameDataChannel.current && gameDataChannelOpen) || pairedWith === 'ChatGPT')) {
      setMove(move);
      move.promotion = 'q'; // Always promote to a queen for simplicity
      chess.move(move); // this is the move that push the move in to the chess instance

      setBoard(chess.board());
      setLastMove({ fromX, fromY, toX, toY });
      
      const fen = chess.fen(); // send the FEN string to the other player
      checkChessGameState(chess);
      setIsYourTurn(false);
      setTurn('opponent');
      if (gameDataChannel.current && gameDataChannelOpen)
      {
        gameDataChannel.current.send(JSON.stringify({ type: 'move', move, fen }));
      } 
      else if (pairedWith === 'ChatGPT') 
      {
        sendMoveToChatGPT(fen, move);
      } 
      return
    }
  };

  const sendMoveToChatGPT = (fen, move, retry = false) => {
    const message = JSON.stringify({ type: 'move', fen, move, retry });
    if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(message);
    } else {
        console.error('WebSocket is not connected.');
    }
  }

  const addMoveToChessBoard = (fen, move) => {;
    const newChess = new Chess(fen); // Create a new Chess instance with the received FEN string
    setChess(newChess); // Update the Chess instance in the state
    setBoard(newChess.board());
    checkChessGameState(newChess);
    setIsYourTurn(true);
    setTurn('your');
    setLastMove({
      fromX: 8 - parseInt(move.from[1]),
      fromY: move.from.charCodeAt(0) - 97,
      toX: 8 - parseInt(move.to[1]),
      toY: move.to.charCodeAt(0) - 97,
    });
  }

  const checkChessGameState = (chessState) => {
    if (chessState.inCheck()) {
      setWhiteKingInCheck(chessState.turn() === 'w');
      setBlackKingInCheck(chessState.turn() === 'b');
    } else {
      setWhiteKingInCheck(false);
      setBlackKingInCheck(false);
    }
    
    if (chessState.isCheckmate()) {
      setIsGameOver(true);
      setWinner(chessState.turn() === 'w' ? 'Black' : 'White');
    } else if (chessState.isStalemate()) {
      setIsGameOver(true);
      setWinner('Stalemate');
    } else if (chessState.isDraw()) {
      setIsGameOver(true);
      setWinner('Draw');
    } else if (chessState.isThreefoldRepetition()) {
      setIsGameOver(true);
      setWinner('Threefold repetition');
    } else if (chessState.isInsufficientMaterial()) {
      setIsGameOver(true);
      setWinner('Insufficient material');
    }
  }

  const unpair = () => { 
    websocket.send(JSON.stringify({ 
      type: 'unpair',
      target: pairedWith
    }));
  }

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          width: '400px',
          height: '400px',
          display: 'flex',
          flexWrap: 'wrap',
          border: '1px solid black',
          transform: isFlipped ? 'rotate(180deg)' : 'none', // Apply rotation here
        }}
      >
         {board.map((row, x) =>
          row.map((square, y) => {
            // Determine if the square should be highlighted
            const isHighlighted = (x === lastMove.fromX && y === lastMove.fromY) || (x === lastMove.toX && y === lastMove.toY);
            return (
              <Square
                key={`${x}-${y}`}
                piece={square ? { color: square.color, type: square.type } : null}
                x={x}
                y={y}
                onDrop={handleMove}
                setHoveredSquare={setHoveredSquare}
                hoveredSquare={hoveredSquare}
                whiteKingInCheck={whiteKingInCheck}
                blackKingInCheck={blackKingInCheck}
                isHighlighted={isHighlighted}
                isFlipped={isFlipped}
              />
            );
          })
        )}
      </div>

      {isGameOver && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '5px',
              textAlign: 'center',
            }}
          >
            {winner ? `${winner} wins!` : 'Game over!'}
          </div>
        </div>
      )}
      <ConfirmModal 
        isOpen={showModal} 
        onConfirm={() => {
          setShowModal(false);
          sendMoveToChatGPT(chess.fen(), move, true);
        }}
        onCancel={() => {
          unpair(); 
          setShowModal(false);
          }}
        message={ChatGPTFailMessage} 
        setIsOpen={setShowModal}
      />
      <UnpairModal 
        isOpen={showErrorModal} 
        message={errorMessage} 
        onClose={() => setShowErrorModal(false)}
      />
    </div>
  );
};

const ChessGame = ({gameDataChannel, gameDataChannelOpen, ischallenger, isPaired, setTurn, pairedWith, websocket}) => {

  return (
    <DndProvider backend={HTML5Backend}>
      <Board 
        gameDataChannel={gameDataChannel}
        gameDataChannelOpen={gameDataChannelOpen}
        ischallenger={ischallenger}
        isPaired={isPaired}
        setTurn={setTurn}
        pairedWith={pairedWith}
        websocket={websocket} 
      />
    </DndProvider>
  );
};

export default ChessGame;

