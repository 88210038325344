import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faPhoneSlash } from '@fortawesome/free-solid-svg-icons';
import './AnswerModal.css'; // Make sure to create the corresponding CSS file

const AnswerModal = ({ isOpen, caller, onAnswer, onDecline }) => {
  if (!isOpen) return null;

  return (
    <div className="answer-modal-overlay">
      <div className="answer-modal">
        <p>Call from {caller}</p>
        <div className="answer-modal-actions">
          <button className="answer-button" onClick={onAnswer}>
            <FontAwesomeIcon icon={faPhone} className="answer-icon" />
          </button>
          <button className="decline-button" onClick={onDecline}>
            <FontAwesomeIcon icon={faPhoneSlash} className="decline-icon" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AnswerModal;
