import React, { useState } from 'react';
import Modal from './components/Modal';
import badWordsList from './bad_words.json'

const LoginScreen = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [ws, setWs] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleUsernameChange = (event) => {
    let input = event.target.value;
    input = input.replace(/\s+/g, ' '); // Replace multiple spaces with a single space
    const trimmedInput = input.trimStart(); // Remove leading spaces
    if (trimmedInput.length <= 15) { // Check character limit
      setError('');
      setUsername(trimmedInput);
    } else {
      setError('Username must be less than or equal to 15 characters.');
    }
  };

  const isUsernameValid = (username) => {
    return !badWordsList.some(badWord => username.includes(badWord));
  };
  
  const handleConnect = () => {
    const trimmedUsername = username.trim(); // Remove leading and trailing spaces

    if (!trimmedUsername) {
      setError('Username cannot be empty or just spaces.');
      setIsLoading(false);
      return;
    }
    
    if (!isUsernameValid(trimmedUsername)) {
      setError('Username contains inappropriate words.');
      setIsLoading(false);
      return;
    }

    if (trimmedUsername.length > 15) {
      setError('Username must be less than or equal to 15 characters.');
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    console.log('Connecting to server...');
    const ws = new WebSocket('wss://chesschat.club:65432/ws');
    setWs(ws);

    ws.onopen = () => {
      ws.send(JSON.stringify({ username: trimmedUsername })); // Send the trimmed username
    };

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.status === 'registered') {
        if (message.username !== username) {
          setUsername(message.username);
          setShowModal(true);
        } else {
          onLoginSuccess(username, ws);
        }
      }
    };

    ws.onclose = () => {
      console.log('WebSocket connection closed');
      setIsLoading(false);
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      setError('An error occurred while connecting to the server.');
      setIsLoading(false);
    };
  };

  const closeModal = () => {
    setShowModal(false);
    onLoginSuccess(username, ws);
  };

  return (
    <div style={styles.container}>
      <img src={process.env.PUBLIC_URL + '/chess.png'} alt="logo" style={styles.image} />
      {error && <div style={styles.error}>{error}</div>}
      <input
        type="text"
        placeholder="Enter your username"
        value={username}
        onChange={handleUsernameChange}
        style={styles.input}
      />
      <button
        onClick={handleConnect}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          minWidth: '150px', 
          textAlign: 'center', 
        }}
      >
        {isLoading ? 'Connecting...' : 'Connect'}
      </button>
      {showModal && (
        <Modal>
          <p>Your username has been changed to: {username}</p>
          <button onClick={closeModal}>OK</button>
        </Modal>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  image: {
    maxWidth: '320px',
    marginBottom: '20px',
    borderRadius: '20px',
  },
  input: {
    marginBottom: '10px',
    padding: '10px',
    fontSize: '16px',
    width: '300px',
    borderRadius: '5px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
  },
  error: {
    color: 'red',
    marginBottom: '10px',
  },
};

export default LoginScreen;
